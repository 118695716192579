<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Aprovar Horas Extras</h1>
    <ExtraHoursForm
      :show="toAprove.show"
      :info="toAprove.data"
      :inApprove="true"
      :processing="false"
      v-on:cancel="cancelled"
      v-on:extraHourApproved="approved"
    />
    <InterventionDetails
      :show="interventionDetails.show"
      :info="interventionDetails.data"
      v-on:close="InterventionDetailsClosed"
    />
    <div style="height: 65vh">
      <DataTable
        :value="extraHours.data"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        responsiveLayout="scroll"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Não existem horas extas. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column
          field="technical.username"
          header="Técnico"
          sortable
          filterField="technical.username"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter p-col-12"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="day"
          header="Data"
          filterField="day"
          dataType="date"
          sortable
        >
          <template #body="slotProps">
            <span class="clickable-item" @click="approveHour(slotProps.data)">
              {{ getDayOfWeek(slotProps.data.date) }} |
              {{ slotProps.data.date }} ({{ slotProps.data.type }})
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column
          field="start"
          header="Inicio"
          :headerStyle="{ 'min-width': '5%', 'max-width': '5%' }"
          :filterHeaderStyle="{ 'min-width': '5%', 'max-width': '5%' }"
          :bodyStyle="{ 'min-width': '5%', 'max-width': '5%' }"
        />
        <Column
          field="end"
          header="Fim"
          :headerStyle="{ 'min-width': '5%', 'max-width': '5%' }"
          :filterHeaderStyle="{ 'min-width': '5%', 'max-width': '5%' }"
          :bodyStyle="{ 'min-width': '5%', 'max-width': '5%' }"
        />
        <Column
          field="extra_hours"
          header="Horas Extras"
          :headerStyle="{ 'min-width': '5%', 'max-width': '5%' }"
          :filterHeaderStyle="{ 'min-width': '5%', 'max-width': '5%' }"
          :bodyStyle="{ 'min-width': '5%', 'max-width': '5%' }"
        />
        <Column header="Info">
          <template #body="slotProps">
            <div class="p-d-flex p-flex-column">
              <span>
                {{ slotProps.data.interventionInfo }}
              </span>
              <span class="p-d-flex p-flex-column">
                <span
                  ><b>Dia Anterior</b>
                  {{ getDayOfWeek(slotProps.data.tasksDayBefore.day) }}
                </span>
                <span class="p-ml-3"
                  >Manhã: {{ slotProps.data.tasksDayBeforeWork.morning }}</span
                >
                <span class="p-ml-3"
                  >Tarde:
                  {{ slotProps.data.tasksDayBeforeWork.afternoon }}</span
                >
              </span>
              <span class="p-d-flex p-flex-column">
                <span
                  ><b>Dia Seguinte</b>
                  {{ getDayOfWeek(slotProps.data.tasksDayAfter.day) }}</span
                >
                <span class="p-ml-3"
                  >Manhã: {{ slotProps.data.tasksDayAfterWork.morning }}</span
                >
                <span class="p-ml-3">
                  Tarde: {{ slotProps.data.tasksDayAfterWork.afternoon }}</span
                >
              </span>
              <span class="p-d-flex p-flex-column">
                <span
                  class="clickable-item"
                  @click="showInterventionsDetail(slotProps.data)"
                >
                  <b>Ver Tarefas</b>
                </span>
              </span>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <Paginator
      v-if="extraHours.data != undefined && extraHours.data.length > 0"
      :rows="extraHours.per_page"
      :totalRecords="extraHours.total"
      template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
      :currentPageReportTemplate="'{currentPage} de {totalPages}'"
      @page="paginatorChange"
    ></Paginator>
  </div>
</template>

<script>
import paginatorService from "../services/paginator.service";
import extraHoursService from "../services/extraHours.service";
import ExtraHoursForm from "../components/extraHours/Form.vue";
import InterventionDetails from "../components/extraHours/InterventionDetails.vue";
import { FilterMatchMode } from "primevue/api/";
import { getOnlyDate, getDayOfWeek } from "../helpers/helpers";

export default {
  name: "ExtraHoursApprove",
  components: {
    ExtraHoursForm,
    InterventionDetails,
  },
  data() {
    return {
      loading: true,
      extraHours: [],
      filters: {
        "technical.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        day: { value: null, matchMode: FilterMatchMode.DATE_IS },
      },
      toAprove: {
        show: false,
      },
      interventionDetails: {
        show: false,
        data: {},
      },
    };
  },
  watch: {
    filters() {
      this.onFilterChange();
    },
  },
  async created() {
    this.extraHours = await this.getExtraHoursNotApproved();
  },
  methods: {
    getDayOfWeek(date) {
      return getDayOfWeek(date);
    },
    format(extraHours) {
      let formated = extraHours.map((v) => ({
        ...v,
        day: new Date(v.date),
        interventionInfo: `Pat ${v.intervention.pat} | ${v.intervention.name} | ${v.intervention.project}`,
      }));
      return formated;
    },
    getExtraHoursNotApproved() {
      this.loading = true;
      let params = this.getParamsfromFilter();
      return extraHoursService
        .getForApprovalToApprove(params)
        .then((response) => {
          response.data = this.format(response.data);
          this.loading = false;
          return response;
        });
    },
    getParamsfromFilter() {
      let params = {};
      for (const [key, value] of Object.entries(this.filters)) {
        if (value.value) {
          if (key === "day") {
            params["date"] = getOnlyDate(value.value);
            params["dateMatchMode"] = value.matchMode;
          } else if (key === "technical.username") {
            params["technical"] = value.value;
          } else params[key] = value.value;
        }
      }
      return params;
    },
    async onFilterChange() {
      this.extraHours = await this.getExtraHoursNotApproved();
    },
    paginatorChange(event) {
      this.loading = true;
      let params = this.getParamsfromFilter();
      return paginatorService
        .getItemsWithFilters(this.extraHours.links[event.page + 1].url, params)
        .then((response) => {
          this.extraHours = response;
          this.extraHours.data = this.format(response.data);
          return (this.loading = false);
        });
    },
    cancelled() {
      this.toAprove = { show: false };
    },
    approveHour(data) {
      this.toAprove.data = data;
      return (this.toAprove.show = true);
    },
    approved() {
      const areYou = (aux) => {
        return aux.id == this.toAprove.data.id;
      };
      let index = this.extraHours.data.findIndex(areYou);
      if (index >= 0) {
        this.extraHours.data.splice(index, 1);
      }
      return this.cancelled();
    },
    InterventionDetailsClosed() {
      return (this.interventionDetails = { show: false, data: {} });
    },
    showInterventionsDetail(data) {
      return (this.interventionDetails = { show: true, data: data });
    },
  },
};
</script>
